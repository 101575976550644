<template>
  <Layout tituloPagina="Pagos | Selección de cliente | Facturas pendientes de pago | Confirmación de pago">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title" v-show="totalACobrar > 0">Total a cobrar</h4>
        <h4 class="card-title" v-show="totalACobrar == 0 && !usarSaldoAFavor">
          Pago adelantado
        </h4>
        <h4 class="card-title" v-show="totalACobrar == 0 && usarSaldoAFavor">
          Aplicación de saldo a favor
        </h4>
        <div>
          <a href="#" @click="cerrar()" onclick="return false;">
            <i class="mdi mdi-close text-dark"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-5">
            <h5>Cliente</h5>
            <strong>ID:</strong> {{ cliente.id }} <br />
            <strong>Nombre:</strong> {{ cliente.nombre }} {{ cliente.apellido_paterno }}
            {{ cliente.apellido_materno }} <br />
            <br />
            <strong>Dirección:</strong> <br />
            {{ cliente.calle }}, N° Ext. {{ cliente.numero_exterior }}, N°
            Int. {{ cliente.numero_interior }} <br />

            {{ cliente.colonia }} <br />
            {{ cliente.municipio }} <br />
            {{ cliente.estado_residencia }} <br />
            {{ cliente.pais }}
          </div>
          <div class="col-md-7">
            <div v-if="usarSaldoAFavor == true || totalACobrar > 0">
              <h5>Facturas a pagar</h5>
              <div class="contenedorTblFacturas table-responsive">
                <table class="tblFacturas table table-bordered table-striped ">
                  <thead>
                    <tr>
                      <th>Folio</th>
                      <th>Fecha</th>
                      <th>Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="factura in facturas" :key="factura.id">
                      <td>
                        {{ factura.ns }}
                      </td>
                      <td>{{ formatoFecha(factura.fecha) }}</td>
                      <td class="text-right">
                        {{ hrr.fmon(factura.saldo, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                      </td>
                    </tr>

                    <tr v-show="usarSaldoAFavor">
                      <td colspan="2">
                        Saldo a favor del cliente
                      </td>
                      <td class="text-right">
                        - {{ hrr.fmon(saldoAFavorAUsar, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="!(totalACobrar == 0 && usarSaldoAFavor)">
              <span v-show="totalACobrar > 0">
                <label>Nota acerca del pago (Opcional)</label>
                <br />
              </span>
              <span v-show="totalACobrar == 0">
                <label>Nota acerca del pago adelantado</label>
                <br />
              </span>
              <textarea
                class="form-control"
                name="nota"
                rows="5"
                placeholder="Nota de pago"
                v-model="abono.nota"
              ></textarea
              ><br />

              <div class="row">
                <label class="col-md-6">
                  Método de pago
                </label>
                <div class="col-md-6">
                  <select
                    name="id_metodo_pago"
                    class="form-select"
                    v-model="abono.id_metodo_pago"
                    @change="refrescarBancos()"
                  >
                    <option
                      v-for="metodo in metodosPago"
                      :key="metodo.id"
                      :value="metodo.id"
                    >
                      {{ metodo.nombre }}
                    </option>
                  </select>
                </div>
              </div>

              <template v-if="abono.id_metodo_pago == 2">
                <div class="row">
                  <label class="col-md-6">
                    Banco
                  </label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <router-link
                        :to="{ name: 'bancos' }"
                        class="btn btn-success"
                        title="Nuevo banco"
                      >
                        <i class="fa fa-plus"></i>
                      </router-link>
                      <select
                        name="id_banco"
                        class="form-select"
                        v-model="abono.id_banco"
                      >
                        <option
                          v-for="banco in bancos"
                          :key="banco.id"
                          :value="banco.id"
                        >
                          {{ banco.nombre }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-md-6">
                    ID de operación
                  </label>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      v-model="abono.id_operacion"
                      placeholder="( Opcional )"
                    />
                  </div>
                </div>
              </template>

              <br />

              <div class="row">
                <label class="col-md-6">
                  Total a cobrar
                </label>
                <div class="col-md-6">
                  <div class="input-group">
                    <span class="input-group-text">
                      <i class="bx bx-dollar"></i>
                    </span>
                    <input
                      ref="totalACobrar"
                      name="totalACobrar"
                      class="form-control text-right"
                      :value="hrr.fmon(totalACobrar, { symbol: monedaSistema.simbolo, miles: ',', decimal: '.' })"
                      readonly
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <label class="col-md-6">
                  Importe recibido
                </label>
                <div class="col-md-6">
                  <div class="input-group">
                    <span class="input-group-text">
                      <i class="bx bx-dollar"></i>
                    </span>
                    <input
                      ref="importe"
                      name="importe"
                      type="number"
                      onclick="this.select();"
                      step="0.01"
                      class="form-control text-right"
                      v-model="importeRecibido"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button @click="cerrar()" class="btn btn-secondary">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click="preguntaAplicarSaldoAFavor()"
          v-if="totalACobrar == 0 && usarSaldoAFavor && saldoAFavorAUsar > 0"
          v-show="!cobrando"
        >
          <i class="fa fa-check"></i>
          Aplicar saldo a favor
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click="preguntaRecepcionDinero()"
          v-if="!(totalACobrar == 0 && usarSaldoAFavor)"
          v-show="!cobrando"
        >
          <i class="bx bx-dollar"></i> Cobrar
        </button>
        <button class="btn btn-success" v-if="cobrando" disabled>
          <i class="mdi mdi-spin mdi-loading"></i>
          Cobrando
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import API from '@/API.js'
import ClienteSrv from '@/services/ClienteSrv.js'
import MetodoPagoSrv from '@/services/MetodoPagoSrv.js'
import AbonoSrv from '@/services/AbonoSrv.js'
import BancoSrv from '@/services/BancoSrv.js'

import Swal from 'sweetalert2'
import moment from "moment"
import md5 from 'md5'

export default {
  name: 'ConfirmarPago',
  components: { Layout }, 
  data() {
    return {
      hrr: iu.hrr,
      token: localStorage.getItem('argusblack.token'),
      cobrando: false, // bandera que indica que hay un cobro en progreso
      cliente: {},
      facturas: [],
      metodosPago: [],
      abono: {
        id_cliente: null,
        id_metodo_pago: null,
        id_banco: null,
        id_operacion: '',
        ids_facturas_a_pagar: [],
        importe: 0
      },
      bancos: [],
      totalACobrar: 0,
      importeRecibido: 0,
      importeDevolucion: 0,
      usarSaldoAFavor: false,
      saldoAFavorAUsar: 0
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this
    
    self.cliente.id = parseInt(this.$route.params.id_cliente)
    self.abono.id_cliente = parseInt(self.cliente.id)
    self.totalACobrar = parseFloat(this.$route.params.totalACobrar)
    self.usarSaldoAFavor = this.$route.params.usarSaldoAFavor == 'true' || this.$route.params.usarSaldoAFavor == true ? true : false
    self.saldoAFavorAUsar = parseFloat(this.$route.params.saldoAFavorAUsar)

    if (this.$route.params.facturasAPagar == null) this.cerrar()
    else {
      // Carga de las facturas a pagar
      this.$route.params.facturasAPagar.forEach(afac => {
        this.facturas.push(JSON.parse(afac))
      })

      // Carga de IDs de facturas a pagar en el cuerpo del abono
      this.abono.ids_facturas_a_pagar = []
      this.facturas.forEach(function(factura) {
        self.abono.ids_facturas_a_pagar.push(factura.id)
      })
    }

    // Cargas iniciales
    if (this.$route.params.cliente) this.cliente = JSON.parse( this.$route.params.cliente )
    else this.cargarCliente()

    this.cargarMetodosPago()
  },

  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    cargarCliente: function() {
      var self = this
      var idCliente = this.$route.params.id_cliente

      ClienteSrv.clienteJSON(idCliente).then(response => {
        self.cliente = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'Cliente no encontrado'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMetodosPago: function() {
      var self = this
      
      MetodoPagoSrv.metodosJSON().then(response => {
        let metodos = response.data
        self.metodosPago = metodos

        // Selección del primer método
        if (self.metodosPago.length > 0)
          self.abono.id_metodo_pago = self.metodosPago[0].id
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    cobrar: function() {
      var self = this,
        abono = Object.assign({}, this.abono)

      // Carga del importe a cobrar
      if (self.totalACobrar > 0) {
        // Si el total a cobrar es mayor que cero, se procede a cobrarlo
        if (self.importeRecibido >= self.totalACobrar && abono.id_metodo_pago == 1)
          abono.importe = self.totalACobrar
        else 
          abono.importe = self.importeRecibido
      } else {
        // Si el total a cobrar es 0, significa que se trata de un abono adelantado,
        // En dicho caso, el abono será registrado por el total del importe recibido
        abono.importe = self.importeRecibido
      }

      // Si la bandera "usarSaldoAFavor" viene habilitada,
      // se enviará el abono con un parámetro adicional que
      // indica se debe de usar primero el saldo a favor del cliente
      if (self.usarSaldoAFavor) abono['usar_saldo_a_favor'] = true

      self.cobrando = true
      
      AbonoSrv.guardar(abono).then(response => {
        let cadena = response.data,
            array = cadena.split(',')
        if (isNaN(array[0])) {
          iu.msg.success('Se aplicó correctamente el saldo a favor a las facturas')
          self.atras()
        } else {
          let idPago = array[0], created_at = array[1]
          self.mostrarMensajePagoRegistrado(idPago, created_at, abono.id_metodo_pago)
        }

        self.cobrando = false
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo registar el pago'
        }
        iu.msg.error(mensaje)
        console.log(error)

        self.cobrando = false
      })
    },

    formatoFecha: function (mt) {
      return moment(mt, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    mostrarMensajePagoRegistrado: function(idPago, created_at, idMetodoPago) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      // Cálculo del importe a devolver
      if (this.importeRecibido > this.totalACobrar && idMetodoPago == 1) {
        this.importeDevolucion = this.importeRecibido - this.totalACobrar
      } else {
        this.importeDevolucion = 0;
      }

      if (this.totalACobrar > 0 && this.importeDevolucion > 0) {
        swal.fire({
          title: 'Pago registrado',
          html: 
            '<span style="font-size:20px;">Devuelve al cliente la cantidad de </span>' +
            '<strong style="font-size:40px">' +
            self.hrr.fmon((Math.round(this.importeDevolucion * 100) / 100), { symbol: self.monedaSistema.simbolo, miles: ",", decimal: "." }, self.monedaSistema.codigo) +
            '</strong>',
          icon: "question",
          confirmButtonText: "OK"
        }).then(result => {
          if (result.value) { 
            self.preguntaImpresionTicketPago(idPago, created_at)
          }
        }).catch(error => {
          self.preguntaImpresionTicketPago(idPago, created_at)
        })
      } else {
        self.preguntaImpresionTicketPago(idPago, created_at)
      }
    },

    preguntaAplicarSaldoAFavor: function() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "Aplicación de saldo a favor",
        html: '¿Deseas aplicar el saldo a favor que tiene el cliente para el pago de las facturas?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.cobrar()
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    preguntaImpresionTicketPago: function(idPago, created_at) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "Impresión de ticket de pago",
        html: '¿Deseas imprimir el ticket de pago?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          let url = API+"/abonos/"+idPago+"/ticket/"+md5(idPago.toString())
          window.open(url,'_blank')
          self.atras()
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          self.atras()
        }
      })
    },

    preguntaRecepcionDinero: function() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      // No está permitido un importe menor que 0
      if (self.importeRecibido < 0) {
        iu.msg.error('Es necesario registrar una cantidad mayor a 0')
        self.importeRecibido = 0
        self.$refs.importe.select()
        return
      }

      if (self.importeRecibido == 0 && self.saldoAFavorAUsar == 0) {
        iu.msg.warning('Es necesario registres la cantidad de dinero que vas a cobrar')
        self.$refs.importe.select()
        return
      }

      if (self.importeRecibido > 0) {
        let msg = '¿Ya recibió el dinero los <strong>'+
          self.hrr.fmon(
            self.importeRecibido, {
              symbol: self.monedaSistema.simbolo,
              miles: ",", decimal: "."
            }, self.monedaSistema.codigo
          )+'</strong>?'

        if(self.abono.ids_facturas_a_pagar.length == 0)
          msg += "<br><br> No hay factura seleccionada o creada, por lo que el cobro quedará como <strong>saldo a favor</strong> en la cuenta"

        swal.fire({
          title: "Recepción de dinero",
          html: msg,
          icon: "question",
          confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
          cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            self.cobrar(self.mostrarCambioADevolver)
          } else if ( result.dismiss === Swal.DismissReason.cancel ) {
            swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
          }
        })
      } else {
        swal.fire({
          title: "Uso de saldo a favor del cliente",
          html: '¿Desea usar el saldo a favor del cliente para pagar las facturas?',
          icon: "question",
          confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
          cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            self.cobrar()
          } else if ( result.dismiss === Swal.DismissReason.cancel ) {
            swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
          }
        })
      }
    },

    refrescarBancos: function() {
      var self = this
      
      BancoSrv.bancosJSON().then(response => {
        self.bancos = response.data

        // Selección del primer banco
        if (self.bancos.length > 0) self.abono.id_banco = self.bancos[0].id
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : ''
        console.log(error)
      })
    }
  },
}
</script>

<style scoped>
input[name='totalACobrar'] {
  font-size: 20px;
  font-weight: bold;
}
input[name='importe'] {
  font-size: 20px;
  font-weight: bold;
}
</style>