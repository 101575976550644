import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/clientes`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  abonoDisponible(idCliente) {
    return apiClient.get(`${idCliente}/abono-disponible`)
  },

  abonosJSON(idCliente, params) {
    params = params || {}
    return apiClient.get(`${idCliente}/abonos.json`, { params: params })
  },

  actualizar(cliente) {
    let formData = new FormData(),
        headers = { headers: { 'Content-Type':'multipart/form-data' } }

    Object.keys(cliente).forEach(key => {
      if(cliente[key] == null) return
      formData.append(key, cliente[key])
    })

    if(cliente.telefonos !== undefined){
      formData.delete('telefonos')
      cliente.telefonos.forEach((telefono, index) => {
        formData.append(`telefonos[${index}][numero]`, telefono.numero)
        formData.append(`telefonos[${index}][prefijo]`, telefono.prefijo)
        formData.append(`telefonos[${index}][tipo]`, telefono.tipo)
      })
    }

    return apiClient.post(`${cliente.id}`, formData, headers)
  },

  cantidad(params) {
    params = params || {}
    return apiClient.get(`/cantidad`, { params: params })
  },

  clienteJSON(idCliente, params) {
    params = params || {}
    return apiClient.get(`${idCliente}.json`, { params: params})
  },

  clientesJSON(params) {
    return apiClient.get(`${API}/clientes.json`, { params: params })
  },

  contratosJSON(idCliente, params) {
    params = params || {}
    return apiClient.get(`${idCliente}/contratos.json`, { params: params })
  },

  direccionJSON(idCliente) {
    return apiClient.get(`${idCliente}/direccion.json`)
  },

  eliminar(idCliente) {
    return apiClient.delete(`${idCliente}`)
  },

  facturasJSON(idCliente, params) {
    params = params || {}
    return apiClient.get(`${idCliente}/facturas.json`, { params: params })
  },

  filtradosJSON(params) {
    return apiClient.get('filtrados.json', { params: params })
  },

  guardar(cliente) {
    let formData = new FormData(),
        headers = { headers: { 'Content-Type':'multipart/form-data' } }

    Object.keys(cliente).forEach(key => {
      if(cliente[key] == null) return
      formData.append(key, cliente[key])
    })

    if(cliente.telefonos !== undefined){
      formData.delete('telefonos')
      cliente.telefonos.forEach((telefono, index) => {
        formData.append(`telefonos[${index}][numero]`, telefono.numero)
        formData.append(`telefonos[${index}][prefijo]`, telefono.prefijo)
        formData.append(`telefonos[${index}][tipo]`, telefono.tipo)
      })
    }

    return apiClient.post('', formData, headers)
  },

  idsContratos(idCliente) {
    return apiClient.get(`${idCliente}/contratos/ids.json`)
  },

  nombreCompleto(idCliente) {
    return apiClient.get(`${idCliente}/nombre-completo`)
  },

  nActivos() {
    return apiClient.get('con-cpe/cantidad-activos')
  },

  nSuspendidos() {
    return apiClient.get('con-cpe/cantidad-suspendidos')
  },

  restaurar(idCliente) {
    let params = { activo: true }
    return apiClient.post(`${idCliente}`, params)
  },

  retirar(idCliente, params) {
    params = params || {}
    return apiClient.get(`${idCliente}/retirar`, { params: params })
  },

  saldo(idCliente) {
    return apiClient.get(`${idCliente}/saldo`)
  },

  saldosJSON(idCliente) {
    return apiClient.get(`${idCliente}/saldos.json`)
  },

  telefonosCliente(idCliente) {
    return apiClient.get(`${idCliente}/telefonos.json`)
  },

  ticketsSoporte(idCliente, params) {
    params = params || {}
    return apiClient.get(`${idCliente}/tickets-soporte.json`, {
      params: params
    })
  },

  totalAbonos(idCliente) {
    return apiClient.get(`${idCliente}/total-abonos`)
  },

  totalFacturas(idCliente) {
    return apiClient.get(`${idCliente}/total-facturas`)
  },

  totalesJSON(idCliente) {
    return apiClient.get(`${idCliente}/totales.json`)
  },

  ultimoPagoJSON(idCliente) {
    return apiClient.get(`${idCliente}/ultimo-pago.json`)
  },

  clientesSitioJSON(params) {
    params = params || {}
    return apiClient.get('/clientes-sitios.json', { params: params })
  }
}
