import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/abonos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  abonoJSON(idAbono, params) {
    params = params || {}
    return apiClient.get(`${idAbono}.json`, { params: params })
  },

  abonosJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/abonos.json`, { params: params })
  },

  cancelar(idAbono, params) {
    params = params || {}
    return apiClient.post(`${idAbono}/cancelar`, params)
  },

  guardar(abono) {
    return apiClient.post('', abono)
  },

  totalIngresosMes() {
    return apiClient.get('total-ingresos-mes')
  },

  totalesPorMetodosDePago(params) {
    params = params || {}
    return apiClient.get('mes/totales-por-metodos-pago', {
      params: params
    })
  },

  totalPagosPuntualesYConMoraDelMes(params) {
    params = params || {}
    return apiClient.get('mes/total-pagos-puntuales-y-con-mora', {
      params: params
    })
  },

  totalesPorDiaEnMes(params) {
    params = params || {}
    return apiClient.get('mes/totales-por-dia', { params: params })
  },

  totalesPorMesEnAnio(params) {
    params = params || {}
    return apiClient.get('anio/totales-por-mes', { params: params })
  },

  vabonos1JSON(params) {
    params = params || {}
    return apiClient.get(`${API}/vabonos1.json`, { params: params })
  }
}
